<template>
  <v-container>
    <v-dialog v-model="dgSave.show" max-width="400">
      <v-card>
        <v-card-title class="headline">
          Save?
        </v-card-title>
        <v-card-text v-if="items.some(e => e.status == 'Unavailable')" align="left">
          <span class="font-weight-bold">The following assets are unavailable, do you want to save by NOT including them?</span>
          <div v-for="item in items" :key="item.asset.name">
            <span v-if="item.status == 'Unavailable'">{{item.asset.name}}</span>
          </div>
        </v-card-text>
        <v-card-text v-else>
          Are you sure to save and exit?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text v-on:click="dgSave.show = false">
            Cancel
          </v-btn>
          <v-btn color="error" v-on:click="saveForm">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="formEdit.show" persistent max-width="600px">
      <v-form v-on:submit.prevent ref="form-main" v-model="formEdit.valid">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="headline">Editing</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-textarea label="Comment" v-model="formEdit.model.comment" :rules="formEdit.rules.comment" auto-grow rows="2"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-on:click="formEdit.show = false">
              Done
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dgAsset.show" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
      <v-card tile>
        <v-card-title class="pa-0">
          <v-toolbar flat dark color="primary">
            <v-btn icon dark @click="dgAsset.show = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Choose Assets</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="d-inline-flex px-0">
          <v-list width="35%" style="overflow-y: scroll;">
            <v-subheader>Categories</v-subheader>
            <v-list-item-group>
              <v-list-item v-for="category in extraItems.assetscategories" :key="`category-${category.id}`" align="left" v-on:click="chooseCategory(category)">
                {{category.name}}
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-list width="65%" style="overflow-y: scroll;">
            <v-subheader>Asset</v-subheader>
            <v-list-item-group>
              <v-list-item v-if="dgAsset.items.assets != null && dgAsset.items.assets.length <= 0" disabled>
                -- No assets in this category --
              </v-list-item>
              <v-list-item v-for="asset in dgAsset.items.assets" :key="`asset-${asset.id}`" align="left" v-on:click="chooseAsset(asset)">
                {{asset.name}}
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <!-- <v-card-actions class="primary">
          <v-spacer></v-spacer>
          <v-btn color="secondary" text v-on:click="dgAsset.show = false">Cancel</v-btn>
          <v-btn class="success"><v-icon left>mdi-plus</v-icon>Add</v-btn>
        </v-card-actions> -->
        <!-- <div style="flex: 1 1 auto;"></div> -->
      </v-card>
    </v-dialog>
    
    <v-sheet>
      <v-form v-on:submit.prevent ref="form-main" v-model="formMain.valid">
        <v-card flat>
          <v-card-title class="primary white--text">
            <span class="headline">Borrow</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-autocomplete label="Borrower *" v-model="formMain.model.manager_id" :rules="formMain.rules.manager_id" :items="extraItems.users" item-text="name" item-value="id" :loading="formMain.loading.manager" v-on:update:search-input="onUpdateSearchInputManager">
                    <template slot="prepend-inner">
                      <v-tooltip bottom v-if="hasCamera">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on:click="formMain.showScannerManager = !formMain.showScannerManager" v-bind="attrs" v-on="on">mdi-barcode-scan</v-icon>
                        </template>
                        <span>Scan Code</span>
                      </v-tooltip>
                    </template>
                  </v-autocomplete>
                  <StreamBarcodeReader v-if="formMain.showScannerManager" v-on:decode="onScannerDecodeManager"></StreamBarcodeReader>
                </v-col>
                <v-col cols="6">
                  <v-datetime-picker label="Borrow Date *" v-model="computedOutDate" :text-field-props="{rules: formMain.rules.out_date}"></v-datetime-picker>
                </v-col>
                <v-col cols="6">
                  <v-datetime-picker label="Expected Return Date *" v-model="computedExpectedInDate" :text-field-props="{rules: formMain.rules.expected_in_date}"></v-datetime-picker>
                </v-col>
                <v-col cols="12">
                  <v-textarea label="Comment" v-model="formMain.model.comment" :rules="formMain.rules.comment" auto-grow rows="2"></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-container>
            <v-card color="primary white--text pa-2">
              <v-row align="center">
                <v-col cols="12" sm="4">
                  <v-card-title>Assets</v-card-title>
                </v-col>
                <v-col cols="12" sm="4" class="pb-1 pb-sm-4">
                  <v-btn color="accent" v-on:click="refreshAllStatus" block>
                    <v-icon left>mdi-refresh</v-icon>Refresh Status
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="4" class="pt-1 pt-sm-4">
                  <v-text-field v-model="search" clearable flat solo hide-details prepend-inner-icon="mdi-magnify" label="Search"></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12" sm="8" class="pb-0 pb-sm-4">
                  <v-autocomplete dark label="Add Asset" v-model="formMain.model.asset_id" :key="formMain.model.asset_id" :items="extraItems.assets" item-text="name" item-value="id" clearable v-on:update:search-input="onUpdateSearchInputAsset" :search-input.sync="formMain.searchInputAsset" v-on:change="onChangeAsset" v-on:keydown.enter="formMain.searchInputAsset = ''">
                    <template slot="prepend-inner">
                      <v-tooltip bottom v-if="hasCamera">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on:click="formMain.showScannerAsset = !formMain.showScannerAsset" v-bind="attrs" v-on="on">mdi-barcode-scan</v-icon>
                        </template>
                        <span>Scan Code</span>
                      </v-tooltip>
                    </template>
                  </v-autocomplete>
                  <StreamBarcodeReader v-if="formMain.showScannerAsset" v-on:decode="onScannerDecodeAsset"></StreamBarcodeReader>
                </v-col>
                <v-col cols="12" sm="4" class="pt-0 pt-sm-4">
                  <v-btn color="accent" v-on:click="dgAsset.show = true" block>Choose Asset</v-btn>
                </v-col>
              </v-row>
            </v-card>
            <v-data-table :headers="headers" :items="items" item-key="name" :search="search" v-model="selected" :show-select="false">
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on:click="editItem(item)" v-bind="attrs" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on:click="removeItem(item)" v-bind="attrs" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
              <template v-slot:item.status="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" v-if="item.status == 'Loading'" color="info">fas fa-spinner fa-pulse</v-icon>
                    <v-icon v-bind="attrs" v-on="on" v-else-if="item.status == 'Available'" color="success">mdi-check-circle</v-icon>
                    <v-icon v-bind="attrs" v-on="on" v-else-if="item.status == 'Unavailable'" color="error">mdi-close-circle</v-icon>
                    <v-icon v-bind="attrs" v-on="on" v-else-if="item.status == 'Not Found'" color="warning">mdi-alert-circle</v-icon>
                    <v-icon v-bind="attrs" v-on="on" v-else-if="item.status == 'Error'" color="error">mdi-minus-circle</v-icon>
                    <!-- <v-btn><v-icon>mdi-pencil</v-icon></v-btn> -->
                  </template>
                  <span>{{item.status}}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-on:click="refreshStatus(item)" v-bind="attrs" v-on="on"><v-icon>mdi-refresh</v-icon></v-btn>
                  </template>
                  <span>Refresh Status</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="!formMain.valid || !items.some(e => e.status == 'Available')" v-on:click="confirmSave">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>
var moment = require('moment-timezone');
export default {
  data: function() {
    return {
      search: "",
      headers: [
        { text: 'Asset', value: 'asset.name' },
        // { text: 'Expected Return Date', value: 'expected_in_date', width: '110px' },
        { text: 'Comment', value: 'comment', width: '25%'},
        { text: 'Status', value: 'status', width: '120px'},
        { text: 'Actions', value: 'actions', sortable: false, width: '120px' },
      ],
      items: [],
      selected: [],
      editingItem: null,
      dgSave: {
        show: false,
        item: null,
      },
      extraItems: {
        assets: [],
        users: [],
      },
      formMain: {
        show: false,
        isAddMode: true,
        valid: false,
        showScannerManager: false,
        showScannerAsset: false,
        scannerLastResult: null,
        searchInputAsset: null,
        loading: {
          manager: false,
          asset: false
        },
        model: {
          id: null,
          manager_id: null,
          asset_id: null,
          asset: null,
          out_date: null,
          in_date: null,
          expected_in_date: null,
          comment: null,
          manager: null,
          outtype_id: null,
          outtype: null,
        },
        rules: {
          out_date: [
            v => !!v || 'Required'
          ],
          expected_in_date: [
            v => !!v || 'Required',
            v => moment(v) > moment(this.formMain.model.out_date) || 'Must be after Borrow Date'
          ],
          manager_id: [
            v => !!v || 'Required'
          ]
        }
      },
      formEdit: {
        show: false,
        valid: false,
        loading: {
        },
        model: {
          comment: null,
        },
        rules: {
        }
      },
      dgAsset: {
        show: false,
        items: {
          assets: null
        }
      },
    }
  },
  computed: {
    computedOutDate: {
      set: function(val) {
        if(val == '' || val == null)
          this.formMain.model.out_date = null;
        else if(moment(val).isValid())
          this.formMain.model.out_date = this.formatToUTC(val);

        this.refreshAllStatus();
      },
      get: function() {
        return this.formatDT(this.formMain.model.out_date);
      }
    },
    computedExpectedInDate: {
      set: function(val) {
        if(val == '' || val == null)
          this.formMain.model.expected_in_date = null;
        else if(moment(val).isValid())
          this.formMain.model.expected_in_date = this.formatToUTC(val);

        this.refreshAllStatus();
      },
      get: function() {
        return this.formatDT(this.formMain.model.expected_in_date);
      }
    },
  },
  mounted() {
    this.getAllItems();
    this.getExtraItems();
  },
  methods: {
    getAllItems: function() {
      // var _this = this;
      // this.get('/inoutsbatch/getall/1001', function(res){
      //   _this.items = res['data']['data']
      // })
    },
    getExtraItems: function() {
      var _this = this;
      this.get('/user/getall', function(res){
        _this.extraItems.users = res['data']['data']
      })
      this.get('/asset/getall', function(res){
        _this.extraItems.assets = res['data']['data']
      })
      this.get('/assetscategory/getall', function(res){
        _this.extraItems.assetscategories = res['data']['data']
      })
    },
    addItem: function() {
      this.formMain.isAddMode = true;
      this.formMain.show = true;
      this.resetForm();
    },
    saveForm: function() {
      if (!this.formMain.valid) return;

      var url = '/inoutsbatch/borrowItems';

      var assets = [];
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        if(item.asset_id != null)
          assets.push({id: item.asset_id, comment: item.comment});
      }

      var data = {
        manager_id: this.formMain.model.manager_id,
        out_date: this.formMain.model.out_date,
        expected_in_date: this.formMain.model.expected_in_date,
        comment: this.formMain.model.comment,
        assets: assets
      };

      var _this = this;
      this.post(url, data, function(res){
        _this.navTo('/Borrows/Details/' + res['data']['data'].id);
      }, function(){
      }, {showSuccess: false, showError: true, showLoading: true});
    },
    resetForm: function() {
      this.formMain.model.id = null;
      this.formMain.model.name = null;
      this.$refs['form-main'].resetValidation();
    },
    editItem: function(item) {
      this.formEdit.model = item;
      this.formEdit.show = true;
    },
    removeItem: function(item) {
      var _this = this;

      for (let i = 0; i < this.items.length; i++) {
        const ele = this.items[i];
        if(ele == item) {
          this.items.splice(i,1);
        }
      }
    },
    confirmSave: function(item) {
      this.dgSave.show = true;
    },
    viewItem: function(item) {
        if(item){
            if(item.id)
                this.navTo("/Borrows/Details/" + item.id);
            else
                this.navTo("/Borrows/Details/-1");
        }
        else
            this.navTo("/Borrows/Details");
    },
    
    onScannerDecodeManager: function(result) {
      // var _this = this;
      // this.formMain.model.code = result
      this.onUpdateSearchInputManager(result);
      this.formMain.showScannerManager = false;
    },
    onScannerDecodeAsset: function(result) {
      // var _this = this;
      // this.formMain.model.code = result
      console.log(result);
      if(result == this.scannerLastResult) return;
      this.scannerLastResult = result;
      this.onUpdateSearchInputAsset(result);
      // this.formMain.showScannerAsset = false;
    },
    onUpdateSearchInputManager: function(e) {
      if(!e) return;
      var code = e.match(/\d/g);
      if(!code) return;
      code = code.join('')
      console.log(code);
      if(code.length != 16) return;
      var _this = this;
      this.formMain.loading.manager = true;
      this.get('/user/getByCode/' + code, function(res){
        if(res['data']['data']){
          _this.formMain.model.manager = null
          _this.formMain.model.manager_id = null
          _this.$nextTick(() => {
            _this.formMain.model.manager = res['data']['data']
            _this.formMain.model.manager_id = _this.formMain.model.manager.id
          })
        }
        _this.formMain.loading.manager = false;
      }, function(){
        _this.formMain.loading.manager = false;
      }, null, {showSuccess: false, showError: true, showLoading: false})
    },
    onUpdateSearchInputAsset: function(e) {
      if(!e) return;
      var code = e.match(/\d/g);
      if(!code) return;
      code = code.join('')
      console.log(code);
      if(code.length != 16) return;

      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        if(item.asset.name == e || item.asset.code == e){
          this.$store.state.snackBar = {
						show: true, type: 'warning', text: 'Asset already exists in the list'
					};
          return;
        }
      }

      var _this = this;

      var item = {
        asset: {
          name: code
        },
        comment: null,
        status: 'Loading'
      };

      this.$store.state.snackBar.show = false;
      this.$nextTick(() => {
        this.$store.state.snackBar = {
          show: true, type: 'success', text: 'Asset added to the list'
        };
      })
      
      this.items.unshift(item);
      this.formMain.model.asset_id = null;
      this.$nextTick(() => {
        this.formMain.searchInputAsset = '';
      })
      
      this.getAssetByCode(item, code);
    },
    getAssetByCode: function(item, code){
      var _this = this;
      this.get('/asset/getByCode/' + code, function(res){
        if(res['data']['data']){
          item.asset = res['data']['data']
          item.asset_id = item.asset.id
          _this.getAssetStatus(item)
        }else{
          item.status = 'Not Found'
        }
      }, function(res){
        item.status = 'Error';
      }, {showSuccess: false, showError: false, showLoading: false});
    },
    onChangeAsset: function(e) {
      this.$nextTick(() => {
        this.formMain.model.asset_id = null;
        this.formMain.searchInputAsset = '';
      })
      console.log("onchange" , e);
      if(!e) return;

      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        if(item.asset_id == e)
          return;
      }

      var asset;
      for (let i = 0; i < this.extraItems.assets.length; i++) {
        const ele = this.extraItems.assets[i];
        if(ele.id == e){
          asset = ele;
          break;
        }
      }

      var item = {
        asset_id: e,
        asset: asset,
        comment: null,
        status: 'Loading'
      };
      this.items.unshift(item);
      
      this.$store.state.snackBar.show = false;
      this.$nextTick(() => {
        this.$store.state.snackBar = {
          show: true, type: 'success', text: 'Asset added to the list'
        };
      })
      
      this.getAssetStatus(item);
    },
    getAssetStatus: function(item) {
      var _this = this;
      var out_date = this.formMain.model.out_date ? this.formMain.model.out_date : '';
      var expected_in_date = this.formMain.model.expected_in_date ? this.formMain.model.expected_in_date : '';
      this.get('/asset/getStatus/' + item.asset_id + `?out_date=${out_date}&in_date=${expected_in_date}` , function(res){
        if(res['data']['data']){
          item.status = res['data']['data'].status
        }else{
          item.status = 'Error'
        }
      }, function(res){
        item.status = 'Error';
      }, {showSuccess: false, showError: false, showLoading: false});
    },
    refreshStatus: function(item) {
      item.status = 'Loading';
      if(item.asset_id == null){
        this.getAssetByCode(item, item.asset.name);
      }else{
        this.getAssetStatus(item);
      }
    },
    refreshAllStatus: function() {
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        item.status = 'Loading';
        if(item.asset_id == null){
          this.getAssetByCode(item, item.asset.name);
        }else{
          this.getAssetStatus(item);
        }
      }
    },
    chooseCategory: function(category) {
      this.dgAsset.items.assets = null;

      this.dgAsset.items.assets = this.extraItems.assets.filter(asset => asset.category_id == category.id);
    },
    chooseAsset: function(asset) {
      this.onChangeAsset(asset.id);
      this.dgAsset.show = false;
    }
  }
}
</script>